import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Buyer from '@/models/Buyer'
import Wholesaler from '@/models/Wholesaler'
import Seller  from '@/models/Seller'

@Model()
export default class Balance extends ApplicationRecord {
  static jsonapiType = 'balances'

  @Attr() id!: string
  @Attr() amount!: number

  @BelongsTo() buyer!: Buyer
  @BelongsTo() wholesaler!: Wholesaler
  @BelongsTo() seller!: Seller
}
