



























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import Pagination from '@/components/Pagination/index.vue'
import DirectUpload from '@/components/DirectUpload/index.vue'

import { Balance, Buyer, BankTransfer, Wholesaler, Seller } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'BalancesTable',
  components: {
    Pagination,
    DirectUpload
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: Balance[] = []
  private currentList = 'Buyer'
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    name: undefined,
    buyerId: '',
    sellerId: '',
    wholesalerId: ''
  }

  private buyersList : Buyer[] = []
  private sellersList: Seller[] = []
  private wholesalersList: Wholesaler[] = []
  private buyersLoading = true
  private sellersLoading = true
  private wholesalersLoading = true

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private topupDialogVisible = false

  private rules = {
    amount: [{ required: true, message: 'amount is required', trigger: 'blur' }]
  }

  private tempData = new Balance({ buyer: new Buyer({ id: '' }) })
  private tempTopup = new BankTransfer({ manageable: new Buyer({ id: '' })})

  created() {
    this.getList()
    this.getBuyersList()
    this.getSellersList()
    this.getWholesalersList()
  }

  private async getList() {
    this.listLoading = true

    const whereParams: any = {
      buyerId: this.listQuery.buyerId,
      sellerId: this.listQuery.sellerId,
      wholesalerId: this.listQuery.wholesalerId
    }

    if (this.currentList === 'Buyer') {
      whereParams.byBuyerNameAlphabetical = true
    } else if (this.currentList === 'Seller') {
      whereParams.bySellerNameAlphabetical = true
    }

    const { data, meta } =
      await Balance
        .where(whereParams)
        .includes([this.currentList === 'Buyer'? 'Buyer': 'Seller', 'wholesaler'])
        .page(this.listQuery.page)
        .per(this.listQuery.limit)
        .order({ createdAt: 'desc' })
        .stats({ total: 'count' }).all()

    this.list = data

    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private async getBuyersList() {
    const { data } = await Buyer
      .select(['name'])
      .order({ name: 'asc' })
      .per(999)
      .page(1)
      .all()

    this.buyersList = data
    this.buyersLoading = false
  }

  private async getSellersList() {
    const { data } = await Seller
      .select(['name'])
      .order({ name: 'asc' })
      .per(999)
      .page(1)
      .all()

    this.sellersList = data
    this.sellersLoading = false
  }

  private async getWholesalersList() {
    const { data } = await Wholesaler
      .select(['name'])
      .order({ name: 'asc' })
      .per(999)
      .page(1)
      .all()

    this.wholesalersList = data
    this.wholesalersLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private resetTempData() {
    this.tempData = new Balance({ buyer: this.buyersList[0].dup() })
  }

  private handleCreateTopup() {
    this.tempTopup = new BankTransfer({ manageable: this.buyersList[0].dup() })
    this.topupDialogVisible = true
  }

  private async createData() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: 'buyer.id' })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          this.list.unshift(data)
          this.total += 1
          this.dialogFormVisible = false
          this.$notify({
            title: 'Balance created',
            message: 'Balance successfully created',
            type: 'success',
            duration: 2000
          })
        }
      }
    })
  }

  private async createTopup() {
    const data = this.tempTopup

    await data.save({ with: 'manageable.id' })

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.topupDialogVisible = false
      this.$notify({
        title: 'Topup created',
        message: 'Topup successfully created',
        type: 'success',
        duration: 2000
      })

      this.getList()
    }
  }

  private handleUpdate(row: any) {
    this.tempData = row
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private updateData() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: 'buyer.id' })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          const index = this.list.findIndex(v => v.id === data.id)
          this.list.splice(index, 1, data)
          this.dialogFormVisible = false
          this.$notify({
            title: 'Balance Updated',
            message: 'Balance successfully updated',
            type: 'success',
            duration: 2000
          })
        }
      }
    })
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Balance> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private async handleDelete(data: Balance, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.list.splice(index, 1)
      this.total -= 1
    }
  }
}
